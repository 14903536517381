import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

import { AppWrap, MotionWrap } from "../../wrapper";
import "./Work.scss";
import { images } from "../../constants";

const Work = () => {
  const [works, setWorks] = useState([]);
  const [filterWork, setFilterWork] = useState([]);
  const [activeFilter, setActiveFilter] = useState("All");
  const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 });

  useEffect(() => {
    const data = [
      {
        title: "React JS",
        name: "Rayzi Web",
        codeLink: "",
        projectLink: "",
        description:
          "Developed a dynamic social platform that seamlessly integrates modern trends, offering Short Video creation, Post sharing, Video Calling, Livestreaming, Chat, and secure Withdrawal functionalities. The project is designed to foster robust user engagement and interaction, reflecting a comprehensive approach to contemporary social networking.",
        imgUrl: images.dating,
        tags: ["React JS", "Node JS", "All"],
      },
      {
        title: "React JS",
        name: "Movie Booking System",
        codeLink: "",
        projectLink: "",
        description:
          "Engineered an advanced movie booking interface with React.js, ensuring a seamless user experience. Implemented a unique single scatter seat algorithm for optimal seat selection efficiency. Demonstrated technical finesse by managing tasks with Jira, utilizing React.js for a dynamic UI, and ensuring efficient deployment through a dedicated CI/CD pipeline on AWS.",
        imgUrl: images.movie,
        tags: ["React JS", "Node JS", "DevOps", "All"],
      },
      {
        title: "React JS",
        name: "Pizza Customization App",
        codeLink: "",
        projectLink: "",
        description:
          "Crafted a user-friendly React.js app with TypeScript for personalized pizza customization, empowering users to tailor preferences. Streamlined task management through Jira, with Google API facilitating efficient pizza delivery logistics. Established a seamless CI/CD pipeline for AWS deployment using Nginx, showcasing proficiency in Docker and Jenkins for effective automation.",
        imgUrl: images.pizza,
        tags: ["React JS", "Node JS", "DevOps", "All"],
      },
      {
        title: "React JS",
        name: "Unique Class",
        codeLink: "",
        projectLink: "",
        description:
          "Developed a native application with Node.js and React.js, integrating a robust backend and Admin Panel for seamless course creation and upload. Enabled student-teacher interaction through an admin panel chat. Leveraged Socket.io and MongoDB, hosted on Digital Ocean, integrated payment gateways, and maintained version control with GitHub.",
        imgUrl: images.course,
        tags: ["React JS", "Node JS", "All"],
      },
      {
        title: "Mobile App",
        name: "To Do App",
        codeLink: "",
        projectLink: "",
        description:
          "I initiated my journey into React Native by developing a mobile app for task management. Leveraging the power of React Native, I crafted a user-friendly to-do app, marking the beginning of my exploration into cross-platform mobile application development.",
        imgUrl: images.todo,
        tags: ["Mobile App", "All"],
      },
      {
        title: "Machine Learning",
        name: "Covid-19 Prediction",
        codeLink: "",
        projectLink: "",
        description:
          "In my university assignment, I delved into machine learning, applying diverse algorithms like supervised, unsupervised, and reinforcement learning to predict COVID-19 disease patients. This project marked a significant exploration into the realms of data science, enhancing my proficiency in leveraging various machine learning techniques for impactful predictions.",
        imgUrl: images.corona,
        tags: ["Machine Learning", "All"],
      },
      {
        title: "Machine Learning",
        name: "IPL Score Prediction",
        codeLink: "",
        projectLink: "",
        description:
          "Embarked on a machine learning journey by developing an IPL score prediction system, exploring player and team performance. Leveraging various algorithms, I gained insights into supervised learning, enhancing my skills in predicting outcomes. This project not only fostered a deeper understanding of machine learning but also sparked a passion for sports analytics.",
        imgUrl: images.cricket,
        tags: ["Machine Learning", "All"],
      },
    ];

    setWorks(data);
    setFilterWork(data);
  }, []);

  const handleWorkFilter = (item) => {
    setActiveFilter(item);
    setAnimateCard([{ y: 100, opacity: 0 }]);

    setTimeout(() => {
      setAnimateCard([{ y: 0, opacity: 1 }]);

      if (item === "All") {
        setFilterWork(works);
      } else {
        setFilterWork(works.filter((work) => work.tags.includes(item)));
      }
    }, 500);
  };

  return (
    <>
      <h2 className="head-text">
        Unveiling My Creative Projects <span>Portfolio</span> Section
      </h2>

      <div className="app__work-filter">
        {[
          "React JS",
          "Mobile App",
          "Node JS",
          "Machine Learning",
          "DevOps",
          "All",
        ].map((item, index) => (
          <div
            key={index}
            onClick={() => handleWorkFilter(item)}
            className={`app__work-filter-item app__flex p-text ${
              activeFilter === item ? "item-active" : ""
            }`}
          >
            {item}
          </div>
        ))}
      </div>

      <motion.div
        animate={animateCard}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="app__work-portfolio"
      >
        {filterWork.map((work, index) => (
          <div className="app__work-item app__flex" key={index}>
            <div className="app__work-img app__flex">
              <img src={work.imgUrl} alt={work.name} />

              {/* <motion.div
                whileHover={{ opacity: [0, 1] }}
                transition={{
                  duration: 0.25,
                  ease: "easeInOut",
                  staggerChildren: 0.5,
                }}
                className="app__work-hover app__flex"
              >
                <a href={work.projectLink} target="_blank" rel="noreferrer">
                  <motion.div
                    whileInView={{ scale: [0, 1] }}
                    whileHover={{ scale: [1, 0.9] }}
                    transition={{ duration: 0.25 }}
                    className="app__flex"
                  >
                    <AiFillEye />
                  </motion.div>
                </a>
                <a href={work.codeLink} target="_blank" rel="noreferrer">
                  <motion.div
                    whileInView={{ scale: [0, 1] }}
                    whileHover={{ scale: [1, 0.9] }}
                    transition={{ duration: 0.25 }}
                    className="app__flex"
                  >
                    <AiFillGithub />
                  </motion.div>
                </a>
              </motion.div> */}
            </div>

            <div className="app__work-content app__flex">
              <h4 className="bold-text">{work.name}</h4>
              <p className="p-text" style={{ marginTop: 10 }}>
                {work.description}
              </p>

              <div className="app__work-tag app__flex">
                <p className="p-text">
                  {activeFilter === "All" ? work.tags[0] : activeFilter}
                </p>
              </div>
            </div>
          </div>
        ))}
      </motion.div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Work, "app__works"),
  "work",
  "app__primarybg"
);
