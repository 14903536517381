import React from "react";
import { BsInstagram } from "react-icons/bs";
import { FaGithub, FaLinkedin } from "react-icons/fa";

const SocialMedia = () => (
  <div className="app__social">
    <div>
      <a href="https://in.linkedin.com/in/kartik-babariya-267286200">
        <FaLinkedin />
      </a>
    </div>
    <div>
      <a href="https://github.com/kartikbabariya">
        <FaGithub />
      </a>
    </div>
    <div>
      <a href="https://www.instagram.com/__.kartik__25/">
        <BsInstagram />
      </a>
    </div>
  </div>
);

export default SocialMedia;
