import email from "../assets/email.png";
import api from "../assets/api.png";
import figma from "../assets/figma.png";
import flutter from "../assets/flutter.png";
import git from "../assets/git.png";
import graphql from "../assets/graphql.png";
import html from "../assets/html.png";
import javascript from "../assets/javascript.png";
import node from "../assets/node.png";
import react from "../assets/React.js.png";
import aws from "../assets/aws.png";
import next from "../assets/next.png";
import socket from "../assets/socket.png";
import docker from "../assets/docker.png";
import jenkins from "../assets/jenkins.png";
import dating from "../assets/dating.png";
import redux from "../assets/redux.png";
import mongo from "../assets/mongo.png";
import sass from "../assets/sass.png";
import movie from "../assets/movie.png";
import pizza from "../assets/pizza.png";
import course from "../assets/course.png";
import todo from "../assets/todo.png";
import machinelearning from "../assets/machinelearning.png";
import corona from "../assets/corona.jpg";
import cricket from "../assets/cricket.jpg";
import devops from "../assets/devops.jpg";
import typescript from "../assets/typescript.png";

import about01 from "../assets/about01.png";
import about02 from "../assets/about02.png";
import about03 from "../assets/about03.png";
import about04 from "../assets/about04.png";

import profile from "../assets/profile_kartik.png";
import circle from "../assets/circle.svg";
import logo from "../assets/kartik_logo.png";

export default {
  email,
  api,
  figma,
  flutter,
  git,
  graphql,
  html,
  javascript,
  node,
  react,
  redux,
  sass,
  typescript,
  about01,
  about02,
  about03,
  about04,
  profile,
  circle,
  logo,
  aws,
  docker,
  next,
  mongo,
  socket,
  jenkins,
  dating,
  movie,
  pizza,
  course,
  todo,
  corona,
  cricket,
  devops,
  machinelearning,
};
