import React, { useEffect, useState } from "react";

import { images } from "../../constants";
import { AppWrap, MotionWrap } from "../../wrapper";
import "./Contact.scss";
import axios from "axios";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [errorMessages, setErrorMessages] = useState({
    username: "",
    email: "",
    message: "",
  });

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const { username, email, message } = formData;

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrorMessages({ ...errorMessages, [name]: "" });
  };

  const validateForm = () => {
    // Validate form fields
    const newErrorMessages = {};
    if (!(username ?? "").trim()) {
      newErrorMessages.username = "Please enter your name";
    }
    if (!(email ?? "").trim()) {
      newErrorMessages.email = "Please enter your email";
    }
    if (!(message ?? "").trim()) {
      newErrorMessages.message = "Please enter your message";
    }

    // Show error messages
    setErrorMessages(newErrorMessages);

    // Return true if there are no errors, false otherwise
    return Object.keys(newErrorMessages).length === 0;
  };

  const handleSubmit = () => {
    setLoading(true);

    const isFormValid = validateForm();

    if (!isFormValid) {
      setLoading(false);
      return;
    }

    const contact = {
      _type: "contact",
      name: formData.username,
      email: formData.email,
      message: formData.message,
      key: "jasbvjgwqoifhpiohvkhbnxgojo",
    };

    axios
      .post("/contact", contact)
      .then((res) => {
        setLoading(false);
        setIsFormSubmitted(true);
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      <div className="app__footer-cards">
        <div className="app__footer-card ">
          <img src={images.email} alt="email" />
          <a href="mailto:kartikbabariya84@gmail.com" className="p-text">
            kartikbabariya84@gmail.com
          </a>
        </div>
      </div>
      {!isFormSubmitted ? (
        <div className="app__footer-form app__flex">
          <div className="app__flex">
            <input
              className="p-text"
              type="text"
              placeholder="Your Name"
              name="username"
              value={username}
              onChange={handleChangeInput}
            />
          </div>
          {errorMessages.username && (
            <p className="contact-error-text">{errorMessages.username}</p>
          )}
          <div className="app__flex">
            <input
              className="p-text"
              type="email"
              placeholder="Your Email"
              name="email"
              value={email}
              onChange={handleChangeInput}
            />
          </div>
          {errorMessages.email && (
            <p className="contact-error-text">{errorMessages.email}</p>
          )}
          <div>
            <textarea
              className="p-text"
              placeholder="Your Message"
              value={message}
              name="message"
              onChange={handleChangeInput}
            />
          </div>
          {errorMessages.message && (
            <p className="contact-error-text">{errorMessages.message}</p>
          )}
          <button type="button" className="p-text" onClick={handleSubmit}>
            {!loading ? "Send Message" : "Sending..."}
          </button>
        </div>
      ) : (
        <div>
          <h3 className="head-text">Thank you for getting in touch!</h3>
        </div>
      )}
    </>
  );
};

export default AppWrap(
  MotionWrap(Contact, "app__footer"),
  "contact",
  "app__primarybg"
);
