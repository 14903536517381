import React from "react";
import { motion } from "framer-motion";
import { AppWrap, MotionWrap } from "../../wrapper";
import "./Skills.scss";
import { images } from "../../constants";

const Skills = () => {
  const skills = [
    {
      name: "React.js",
      bgColor: "Name",
      image: images.react,
    },
    {
      name: "Node.js",
      bgColor: "Name",
      image: images.node,
    },
    {
      name: "GraphQl",
      bgColor: "Name",
      image: images.graphql,
    },
    {
      name: "Next.js",
      bgColor: "Name",
      image: images.next,
    },
    {
      name: "Redux",
      bgColor: "Name",
      image: images.redux,
    },
    {
      name: "MongoDB",
      bgColor: "Name",
      image: images.mongo,
    },
    {
      name: "GIT",
      bgColor: "Name",
      image: images.git,
    },
    {
      name: "TypeScript",
      bgColor: "Name",
      image: images.typescript,
    },
    {
      name: "Socket.io",
      bgColor: "Name",
      image: images.socket,
    },
    {
      name: "AWS",
      bgColor: "Name",
      image: images.aws,
    },
    {
      name: "Docker",
      bgColor: "Name",
      image: images.docker,
    },
    {
      name: "Jenkins",
      bgColor: "Name",
      image: images.jenkins,
    },
  ];

  const experiences = [
    {
      year: "2023 - Now",
      company: "BugStrike (London)",
      role: "Frontend Developer",
    },
    {
      year: "2021 - 2022",
      company: "Xitij Infotech (India)",
      role: "Full Stack Developer",
    },
  ];

  const school = [
    {
      year: "2023 - 2024",
      company: "University Of West London",
      role: "Msc. Software Engineering",
    },
    {
      year: "2018 - 2022",
      company: "Gujarat Technological University",
      role: "B.E. Computer Engineering (9.00 CGPA)",
    },
  ];

  return (
    <>
      <h2 className="head-text">Skills & Experiences</h2>

      <div className="app__skills-container">
        <motion.div className="app__skills-list">
          {skills.map((skill) => (
            <motion.div
              whileInView={{ opacity: [0, 1] }}
              transition={{ duration: 0.5 }}
              className="app__skills-item app__flex"
              key={skill.name}
            >
              <div
                className="app__flex"
                style={{ backgroundColor: skill.bgColor }}
              >
                <img
                  style={{ objectFit: "contain" }}
                  src={skill.image}
                  alt={skill.name}
                />
              </div>
              <p className="p-text">{skill.name}</p>
            </motion.div>
          ))}
        </motion.div>
        <div className="app__skills-exp">
          <h4 className="text">
            <span className="bold-text">Work Experiences</span>
          </h4>
          {experiences.map((experience) => (
            <motion.div
              className="app__skills-exp-item mt-2"
              key={experience.year}
            >
              <div className="app__skills-exp-year">
                <p className="bold-text">{experience.year}</p>
              </div>
              <motion.div className="app__skills-exp-works">
                <>
                  <motion.div
                    whileInView={{ opacity: [0, 1] }}
                    transition={{ duration: 0.5 }}
                    className="app__skills-exp-work"
                    data-tip
                    data-for={experience.company}
                    key={experience.company}
                  >
                    <h4 className="bold-text">{experience.role}</h4>

                    <p className="p-text">{experience.company}</p>
                  </motion.div>
                </>
              </motion.div>
            </motion.div>
          ))}

          <h4 className="text">
            <span className="bold-text">Education</span>
          </h4>

          {school.map((experience) => (
            <motion.div className="app__skills-exp-item" key={experience.year}>
              <div className="app__skills-exp-year">
                <p className="bold-text">{experience.year}</p>
              </div>
              <motion.div className="app__skills-exp-works">
                <>
                  <motion.div
                    whileInView={{ opacity: [0, 1] }}
                    transition={{ duration: 0.5 }}
                    className="app__skills-exp-work"
                    data-tip
                    data-for={experience.company}
                    key={experience.company}
                  >
                    <h4 className="bold-text">{experience.role}</h4>

                    <p className="p-text">{experience.company}</p>
                  </motion.div>
                </>
              </motion.div>
            </motion.div>
          ))}
        </div>
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Skills, "app__skills"),
  "skills",
  "app__whitebg"
);
