import React, { useState } from "react";
import { motion } from "framer-motion";
import { images } from "../../constants";

import "./About.scss";
import { AppWrap, MotionWrap } from "../../wrapper";

import { HiChevronLeft, HiChevronRight } from "react-icons/hi";

const About = () => {
  const [startIndex, setStartIndex] = useState(0);
  const handleClick = (direction) => {
    const step = window.innerWidth < 768 ? 1 : 2;

    const newIndex =
      direction === "next"
        ? (startIndex + step) % abouts.length
        : (startIndex - step + abouts.length) % abouts.length;
    setStartIndex(newIndex);
  };

  const abouts = [
    {
      title: "Web Development",
      description:
        "Experienced web developer with proficiency in both frontend and backend technologies. Passionate about creating user-friendly and visually appealing web applications.",
      imgUrl: images.about01,
    },
    {
      title: "Frontend Developer",
      description:
        "Dedicated frontend developer specializing in crafting responsive and engaging user interfaces. Adept at turning design concepts into functional and intuitive web experiences.",
      imgUrl: images.react,
    },
    {
      title: "Backend Developer",
      description:
        "Seasoned backend developer with expertise in building robust server-side applications. Experienced in designing and implementing scalable and efficient backend architectures.",
      imgUrl: images.node,
    },
    {
      title: "Machine Learning",
      description:
        "Enthusiastic about the intersection of web development and machine learning. Leveraging data-driven approaches to create intelligent and adaptive applications.",
      imgUrl: images.machinelearning,
    },
    {
      title: "DevOps",
      description:
        "Experienced in DevOps practices, ensuring seamless integration and deployment pipelines. Committed to optimizing development workflows for enhanced collaboration and efficiency.",
      imgUrl: images.docker,
    },
  ];

  return (
    <>
      <h2 className="head-text">
        Versatile Developer: <br />
        <span>Designing Excellence in Innovation.</span>
      </h2>
      <div className="app__profiles">
        {abouts
          .slice(startIndex, startIndex + (window.innerWidth < 768 ? 1 : 3))
          .map((about, index) => (
            <motion.div
              key={about.title + (startIndex + index)}
              whileInView={{ opacity: 1 }}
              whileHover={{ scale: 1.1 }}
              transition={{ duration: 0.5, type: "tween" }}
              className="app__profile-item"
            >
              <img src={about.imgUrl} alt={about.title} />
              <h2 className="bold-text" style={{ marginTop: 20 }}>
                {about.title}
              </h2>
              <p className="p-text" style={{ marginTop: 10 }}>
                {about.description}
              </p>
            </motion.div>
          ))}
      </div>

      <div className="app__testimonial-btns app__flex">
        <div className="app__flex" onClick={() => handleClick("prev")}>
          <HiChevronLeft />
        </div>

        <div className="app__flex" onClick={() => handleClick("next")}>
          <HiChevronRight />
        </div>
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(About, "app__about"),
  "about",
  "app__whitebg"
);
