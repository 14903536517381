import Navbar from "./components/Navbar/Navbar";
import "./App.scss";
import Header from "./Containers/Header/Header";
import About from "./Containers/About/About";
import Work from "./Containers/Work/Work";
import Skills from "./Containers/Skills/Skills";
import Contact from "./Containers/Contact/Contact";
import axios from "axios";
import { useEffect } from "react";

// For Universal Analytics
import ReactGA from "react-ga";

function App() {
  // Set a default base URL for Axios
  axios.defaults.baseURL = "https://kartik-portfolio-wixg.onrender.com"; // Replace with your actual server base URL

  const initGA = () => {
    ReactGA.initialize("G-Y7VNCQ0NT3"); // Your Measurement ID
  };

  const logPageView = () => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  };

  useEffect(() => {
    initGA();
    // Check if the session storage already has a 'visited' item
    if (!sessionStorage.getItem("visited")) {
      logPageView();
      // Set the 'visited' item in session storage to prevent future logs in this session
      sessionStorage.setItem("visited", "true");
    }
  }, []);

  return (
    <>
      <div className="app">
        <Navbar />
        <Header />
        <About />
        <Work />
        <Skills />
        <Contact />
      </div>
    </>
  );
}

export default App;
